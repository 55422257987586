import React from "react";
import withStyles from "react-jss";
import useStoreon from "storeon/react";

import { commonStyles } from "services/theme";

const styles = {
  ...commonStyles
};

export default withStyles(styles)(({ classes, currentPlayer }) => {
  /* eslint-disable no-unused-vars */
  const { game } = useStoreon("game");
  /* eslint-enable no-unused-vars */

  return (
    <div className={`${classes.container} ${classes.fullHeight} ${classes.flexMiddle}`}>
      <h2> Make a choice </h2>
      {game.state.phaseData &&
        game.state.phase === "EventChoices" &&
        game.state.phaseData.event.choices.map((choice, choice_index) => (
          <h3 key={`choice_${choice_index}`} className={classes.mb2}>
            {choice_index + 1}) {choice.buttonTxt}{" "}
            {game.state.phaseData.votes && `(${game.state.phaseData.votes.filter(v => v.choice === choice_index).length})`}
          </h3>
        ))}
    </div>
  );
});
