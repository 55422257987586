import React, { useState, useEffect } from "react";
import withStyles from "react-jss";
import useStoreon from "storeon/react";

import { getAssetUrl } from "services/utilities";
import { bgColors } from "services/theme";

import ElementImage from "modules/Components/ElementImage";

const styles = {
  ...bgColors,
  root: {
    position: "relative",
    textAlign: "center"
  },
  rounded: {
    borderRadius: "50%"
  },
  elementIconWrapper: {
    position: "relative",
    display: "inline-block",
    background: "rgba(0,0,0,0.4)"
  },
  elementIcon: {
    display: "block",
    width: 150,
    height: 150,
    textAlign: "center",
    objectFit: "cover"
  },
  elementName: {
    position: "absolute",
    width: "120%",
    left: "50%",
    transform: "translateX(-50%)"
  },
  placement_top: {
    bottom: "100%"
  },
  placement_bottom: {
    top: "100%"
  },
  placeholder: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center"
  },
  badge: {
    position: "absolute",
    top: "-22%",
    left: "-22%"
  },
  semiBg: {
    padding: "0.5em",
    background: "rgba(0,0,0,0.6)"
  },
  animatePulse: {
    animationName: "$pulseZoom",
    animationIterationCount: "infinite",
    animationDuration: "2s",
    animationDirection: "alternate",
    animationTimingFunction: "ease-in-out"
  },
  "@keyframes pulseZoom": {
    from: { transform: "scale(1)" },
    to: { transform: "scale(1.2)" }
  }
};

export default withStyles(styles)(
  ({
    classes,
    id,
    type,
    variant = "rounded",
    size = 75,
    imageOnly = false,
    color = null,
    badge = null,
    placement = "bottom",
    margin = true,
    textBg = false,
    animated = false
  }) => {
    /* eslint-disable no-unused-vars */
    const [element, setElement] = useState(null);
    const { game } = useStoreon("game");
    /* eslint-enable no-unused-vars */

    useEffect(() => {
      if (game.story) {
        if (type && id !== undefined && type) {
          const el = game.story[type].find(e => e.id === id);
          if (el) {
            setElement(el);
          }
        } else {
          setElement(null);
        }
      }
    }, [id, type, game.story]);

    return (
      <div className={`${classes.root} ${animated ? classes.animatePulse : ""}`} style={{ margin: margin ? size * 0.15 : 0 }}>
        <div className={`${classes.elementIconWrapper} ${variant === "rounded" ? classes.rounded : ""}`} style={{ padding: size / 17 }}>
          {!element && (
            <div
              className={`${classes.placeholder} ${classes.whiteBorder} ${classes.elementsBg} ${variant === "rounded" ? classes.rounded : ""}`}
              style={{
                width: size - 10,
                height: size - 10,
                borderWidth: size / 26,
                fontSize: size * 0.7
              }}
            >
              ?
            </div>
          )}
          {element && (
            <img
              src={element && getAssetUrl(element.asset)}
              className={`${classes.elementIcon} ${classes[`${color || type}Border`]} ${variant === "rounded" ? classes.rounded : ""}`}
              alt=""
              style={{
                width: size - 10,
                height: size - 10,
                borderWidth: size / 26
              }}
            />
          )}
          {badge && (
            <div className={`${classes.rounded} ${classes.badge}`}>
              <ElementImage id={badge.id} type={badge.type} variant="rounded" size={size * 0.6} imageOnly={!badge.showTitle} placement={badge.placement} />
            </div>
          )}
        </div>
        {!imageOnly && (
          <div
            className={`${classes.elementName} ${classes[`placement_${placement}`]} ${textBg ? classes.semiBg : ""}`}
            style={{ fontSize: `${size / 190}em` }}
          >
            {element ? element.name : " "}
          </div>
        )}
      </div>
    );
  }
);
