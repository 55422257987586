import React from "react";
import withStyles from "react-jss";

import { commonStyles } from "services/theme";

const styles = {
  ...commonStyles
};

export default withStyles(styles)(({ classes }) => {
  return (
    <div className={`${classes.container} ${classes.fullHeight} ${classes.flexMiddle}`}>
      <h1>Post Intro</h1>
      <h3>Private Player introduction. Check your phones</h3>
    </div>
  );
});
