import React, { useRef, useEffect } from "react";
import withStyles from "react-jss";
import useStoreon from "storeon/react";

import ElementImage from "modules/Components/ElementImage";
import { commonStyles } from "services/theme";

const styles = {
  ...commonStyles,
  mainName: {
    display: "inline-block",
    padding: 10,
    background: "rgba(0, 0, 0, 0.7)"
  },
  mainBg: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  chatArea: {
    position: "fixed",
    display: "flex",
    bottom: 0,
    left: 0,
    right: 0,
    background: "rgba(0, 0, 0, 0.7)"
  },
  chatElements: {
    position: "absolute",
    bottom: "100%",
    left: "1em",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "top",
    justifyContent: "center",
    marginBottom: "-1.8em",
    zIndex: 2
  },
  chatTexts: {
    display: "block",
    position: "relative",
    padding: "3em 0.5em 1em 0.5em",
    flexGrow: 1,
    maxHeight: "65vh",
    overflow: "auto",
    textAlign: "left"
  },
  chatImage: {
    position: "absolute",
    bottom: 0,
    right: "1em",
    zIndex: 2
  },
  chatImagePlaceholder: {
    display: "block",
    position: "absolute",
    top: "3rem",
    right: 0
  }
};

export default withStyles(styles)(({ classes, bgImage, character, mainName, blocks, blockIndex, allShownElements = null }) => {
  const textContent = useRef(null);
  const { game } = useStoreon("game");
  let timer = null;

  useEffect(() => {
    const el = textContent.current;
    if (el.scrollHeight !== el.clientHeight) {
      scrollElement(el, true);
    }

    return () => {
      clearInterval(timer);
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [blockIndex]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const scrollElement = (element, down) => {
    clearInterval(timer);
    let i = down ? 0 : element.scrollHeight - element.clientHeight;
    timer = setInterval(() => {
      element.scrollTo(0, i);
      i = down ? i + 1 : i - 1;
      if ((down && i >= element.scrollHeight - element.clientHeight) || (!down && i <= 0)) {
        clearInterval(timer);
        setTimeout(function() {
          scrollElement(element, !down);
        }, 1000);
      }
    }, 120);
  };

  return (
    <React.Fragment>
      <div className={`${classes.mainBg} ${classes.fullHeight}`} style={{ backgroundImage: bgImage }}>
        <h4 className={classes.mainName}>{mainName}</h4>
        <div className={classes.chatArea}>
          {allShownElements && (
            <div className={classes.chatElements}>
              {allShownElements.map((asked, asked_index) => (
                <ElementImage
                  key={`askedEl_${asked_index}`}
                  id={asked.id}
                  type={asked.type}
                  variant="rounded"
                  placement="top"
                  size={Math.floor(window.innerWidth / 18)}
                  color={game.state.phaseData.blockData.conditions.includes(asked.id) ? "white" : asked.type}
                  animated={game.state.phaseData.blockData.conditions.includes(asked.id) ? true : false}
                />
              ))}
            </div>
          )}
          <div className={classes.chatTexts} ref={textContent}>
            {blocks[blockIndex].introTxt && (
              <h5 className={classes.fontLarger1}>
                <em>{blocks[blockIndex].introTxt}</em>
              </h5>
            )}
            {blocks[blockIndex].mainTxt && <h5 className={`${classes.mt2} ${classes.fontLarger2}`}>&ldquo;{blocks[blockIndex].mainTxt}&rdquo;</h5>}
          </div>
          {character && (
            <div className={classes.chatImagePlaceholder} style={{ width: `${2 * Math.floor(window.innerWidth / 6)}px` }}>
              <div className={classes.chatImage}>
                <ElementImage id={character} type={"characters"} size={Math.floor(window.innerWidth / 6)} color={"characters"} placement="top" margin={false} />
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
});
