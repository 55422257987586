import axios from "axios";

const API = () => {
  axios.defaults.baseURL = `${process.env.NODE_ENV === "development" ? "http://localhost:3300" : "https://state.namelessnoir.com"}`;
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;

  return {
    setToken: token => localStorage.setItem("token", token),
    get: async (url, options = {}) => {
      const r = await axios.get(url, options);
      return r.data;
    },
    post: async (url, data, options = {}) => {
      const r = await axios.post(url, data, options);
      return r.data;
    },
    put: async (url, data, options = {}) => {
      const r = await axios.put(url, data, options);
      return r.data;
    },
    delete: async (url, options = {}) => {
      const r = await axios.delete(url, options);
      return r.data;
    },
    message: async (dispatch, data) => {
      console.log("mess", data);
      dispatch("ui/setLoading", true);
      const response = await axios.post("/message", data);
      const newState = response.data;
      if (newState.message) {
        dispatch("error/setError", {
          visible: true,
          text: newState.message
        });
      } else {
        dispatch("game/setState", { newState, uid: data.uid });
      }
      dispatch("ui/setLoading", false);
      return newState;
    }
  };
};

export default API;
