const colors = {
  players: { main: "#584976", secondary: "#ffffff" },
  topics: { main: "#486d8d", secondary: "#ffffff" },
  characters: { main: "#9d9246", secondary: "#ffffff" },
  locations: { main: "#709754", secondary: "#ffffff" },
  events: { main: "#9e5454", secondary: "#ffffff" },
  elements: { main: "#cd5749", secondary: "#ffffff" },
  clues: { main: "#1b7a60", secondary: "#ffffff" },
  objectives: { main: "#ca6b33", secondary: "#ffffff" },
  green: { main: "#709754", secondary: "#ffffff" },
  red: { main: "#cd5749", secondary: "#ffffff" },
  white: { main: "#fff", secondary: "#000" }
};

const borderWidth = 3;

const textColors = {
  elementsTxt: {
    color: colors.elements.main
  },
  playersTxt: {
    color: colors.players.main
  },
  topicsTxt: {
    color: colors.topics.main
  },
  charactersTxt: {
    color: colors.characters.main
  },
  locationsTxt: {
    color: colors.locations.main
  },
  eventsTxt: {
    color: colors.events.main
  },
  objectivesTxt: {
    color: colors.objectives.main
  },
  cluesTxt: {
    color: colors.clues.main
  },
  greenTxt: {
    color: colors.green.main
  },
  redTxt: {
    color: colors.red.main
  },
  whiteTxt: {
    color: colors.white.main
  }
};

const bgColors = {
  elementsBg: {
    color: colors.elements.secondary,
    backgroundColor: colors.elements.main
  },
  elementsBorder: {
    border: `${borderWidth}px solid ${colors.elements.main}`
  },
  playersBg: {
    color: colors.players.secondary,
    backgroundColor: colors.players.main
  },
  playersBorder: {
    border: `${borderWidth}px solid ${colors.players.main}`
  },
  topicsBg: {
    color: colors.topics.secondary,
    backgroundColor: colors.topics.main
  },
  topicsBorder: {
    border: `${borderWidth}px solid ${colors.topics.main}`
  },
  charactersBg: {
    color: colors.characters.secondary,
    backgroundColor: colors.characters.main
  },
  charactersBorder: {
    border: `${borderWidth}px solid ${colors.characters.main}`
  },
  locationsBg: {
    color: colors.locations.secondary,
    backgroundColor: colors.locations.main
  },
  locationsBorder: {
    border: `${borderWidth}px solid ${colors.locations.main}`
  },
  eventsBg: {
    color: colors.events.secondary,
    backgroundColor: colors.events.main
  },
  eventsBorder: {
    border: `${borderWidth}px solid ${colors.events.main}`
  },
  cluesBg: {
    color: colors.clues.secondary,
    backgroundColor: colors.clues.main
  },
  cluesBorder: {
    border: `${borderWidth}px solid ${colors.clues.main}`
  },
  objectivesBg: {
    color: colors.objectives.secondary,
    backgroundColor: colors.objectives.main
  },
  objectivesBorder: {
    border: `${borderWidth}px solid ${colors.objectives.main}`
  },
  redBg: {
    color: colors.red.secondary,
    backgroundColor: colors.red.main
  },
  redBorder: {
    border: `${borderWidth}px solid ${colors.red.main}`
  },
  greenBg: {
    color: colors.green.secondary,
    backgroundColor: colors.green.main
  },
  greenBorder: {
    border: `${borderWidth}px solid ${colors.green.main}`
  },
  whiteBg: {
    color: colors.white.secondary,
    backgroundColor: colors.white.main
  },
  whiteBorder: {
    border: `${borderWidth}px solid ${colors.white.main}`
  }
};

const mentionStyles = {
  control: {
    backgroundColor: "#fff",
    fontSize: 12,
    fontWeight: "normal"
  },

  highlighter: {
    overflow: "hidden",
    letterSpacing: 0,
    lineHeight: 1.5,
    textAlign: "left"
  },

  input: {
    margin: 0,
    letterSpacing: 0,
    lineHeight: 1.5,
    textAlign: "left"
  },

  "&singleLine": {
    control: {
      display: "inline-block",
      width: 130
    },

    highlighter: {
      padding: 1,
      border: "2px inset transparent"
    },

    input: {
      padding: 1,
      border: "2px inset"
    }
  },

  "&multiLine": {
    control: {
      border: "none",
      borderBottom: "1px solid white",
      backgroundColor: "transparent",
      fontSize: "1.2em"
    },

    highlighter: {
      padding: 9,
      minHeight: 30,
      outline: 0,
      border: 0
    },

    input: {
      padding: 9,
      minHeight: 30,
      outline: 0,
      border: 0,
      color: "white"
    }
  },

  suggestions: {
    list: {
      maxHeight: 100,
      overflow: "auto",
      position: "absolute",
      bottom: 14,
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 10
    },

    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      backgroundColor: "#222",
      color: "#fff",
      "&focused": {
        backgroundColor: "#444"
      }
    }
  }
};

const commonStyles = {
  textLeft: {
    textAlign: "left"
  },
  margined06: {
    margin: "0.6em"
  },
  margined1: {
    margin: "1em"
  },
  margined2: {
    margin: "2em"
  },
  mt1: { marginTop: "1em" },
  mt2: { marginTop: "2em" },
  mr1: { marginRight: "1em" },
  mr2: { marginRight: "2em" },
  mb1: { marginBottom: "1em" },
  mb2: { marginBottom: "2em" },
  ml1: { marginLeft: "1em" },
  ml2: { marginLeft: "2em" },
  fontLarger1: {
    fontSize: "1.2em"
  },
  fontLarger2: {
    fontSize: "1.4em"
  },
  fontSmaller1: {
    fontSize: "0.7em"
  },
  fontSmaller2: {
    fontSize: "0.5em"
  },
  container: {
    padding: "0 1em",
    overflowX: "hidden"
  },
  btn: {
    padding: "0.5vw 1.5vw",
    background: "none",
    border: "1px solid #eee",
    color: "#eee",
    textAlign: "center",
    fontSize: "0.7em",
    cursor: "pointer"
  },
  btnSmall: {
    fontSize: "0.5em"
  },
  btnLarge: {
    fontSize: "1em"
  },
  btnActive: {
    color: colors.red.secondary,
    borderColor: colors.red.secondary,
    background: colors.red.main
  },
  bgDark: {
    background: "#333"
  },
  fullWidth: {
    width: "100%"
  },
  input: {
    width: "100%",
    padding: "0.8em 1em",
    border: "1px solid #333"
  },
  inputInline: {
    padding: "0.8em 1em",
    border: "1px solid #333"
  },
  box: {
    padding: "2em",
    border: "1px solid #eee"
  },
  twoColumns: {
    columnCount: 2
  },
  flexMiddle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  fullHeight: {
    minHeight: "100vh"
  }
};

export { colors, textColors, bgColors, mentionStyles, commonStyles };
