import { bgColors } from "services/theme";

const isJson = item => {
  let it = typeof item !== "string" ? JSON.stringify(item) : item;

  try {
    it = JSON.parse(it);
  } catch (e) {
    return false;
  }

  if (typeof it === "object" && it !== null) {
    return true;
  }

  return false;
};

var extend = function(out) {
  out = out || {};
  for (var i = 1; i < arguments.length; i++) {
    if (!arguments[i]) continue;

    for (var key in arguments[i]) {
      if (arguments[i].hasOwnProperty(key)) {
        if (JSON.stringify(out[key]) !== JSON.stringify(arguments[i][key])) {
          out[key] = arguments[i][key];
        }
      }
    }
  }
  return out;
};

const camelCase = str => {
  const st = str
    .replace(/-/g, " ") // convert all hyphens to spaces
    .replace(/\s[a-z]/g, str.toLowerCase()) // convert first char of each word to UPPERCASE
    .replace(/\s+/g, "") // remove spaces
    .replace(/^[A-Z]/g, str.toUpperCase()); // convert first char to lowercase
  return st;
};

const getAssetUrl = asset => {
  if (asset && asset.pack && asset.category && asset.file) {
    return asset.file;
  }
  if (asset && asset.pack && asset.type && asset.file) {
    return `/asset_packs/${asset.pack}/${asset.type}/${asset.file}`;
  }
  return "";
};

const getCategoryFromSymbol = symbol => {
  switch (symbol) {
    case "@":
      return "locations";
    case "#":
      return "topics";
    case "^":
      return "characters";
    case "*":
      return "objectives";
    case "%":
      return "players";
    default:
      return false;
  }
};

const formatMentionText = text => {
  const formattedText = text.replace(/(.?)\[(.*?)\]\((.*?)\)/g, function(match, symbol, title, id) {
    const type = getCategoryFromSymbol(symbol);
    const s = bgColors[`${type}Bg`];
    return `<span style="color: ${s.color}; background-color: ${s.backgroundColor};">${title}</span>`;
  });

  return formattedText;
};

const _h = html => {
  return { __html: html };
};

const randFromTo = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

const autoFontSize = text => {
  const slope = (0.8 - 2) / 900;
  let newSize = slope * text.length + 2;
  if (newSize < 0.8) {
    newSize = 0.8;
  }
  if (newSize > 1) {
    newSize = 1;
  }
  return newSize;
};

const getImageSize = (url, callback) => {
  var img = new Image();
  img.onload = function() {
    callback({ width: this.width, height: this.height });
  };
  img.src = url;
};

const isActivePlayer = (uid, checkins, now) => {
  return checkins && checkins[uid] && Math.round((now - checkins[uid].last) / 1000) < 20 ? true : false;
};

export { isJson, extend, camelCase, getAssetUrl, getCategoryFromSymbol, formatMentionText, _h, randFromTo, autoFontSize, getImageSize, isActivePlayer };
