import React from "react";
import withStyles from "react-jss";
import useStoreon from "storeon/react";

import { bgColors, commonStyles } from "services/theme";
import { getAssetUrl } from "services/utilities";

import ElementImage from "modules/Components/ElementImage";

const styles = {
  ...bgColors,
  ...commonStyles,
  root: {
    position: "relative",
    minHeight: "100vh",
    backgroundPosition: "center",
    backgroundSize: "cover",
    color: "#ffffff",
    display: "flex",
    flexDirection: "column",
    fontSize: "1.1em"
  },
  overlayFilter: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,0.6)",
    zIndex: 1
  },
  content: {
    position: "relative",
    zIndex: 2,
    background: "rgba(0,0,0,0.5)"
  },
  title: {
    textDecoration: "underline"
  },
  description: {
    fontStyle: "italic"
  },
  emptySpace: {
    flexGrow: 1
  },
  elementsAsk: {
    position: "relative",
    zIndex: 2,
    fontSize: "0.8em",
    paddingBottom: "2.5em",
    "& h2": {
      textDecoration: "underline",
      marginTop: "2em"
    }
  },
  elementsList: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "top",
    justifyContent: "center"
  }
};

export default withStyles(styles)(({ classes, location }) => {
  /* eslint-disable no-unused-vars */
  const { game } = useStoreon("game");
  /* eslint-enable no-unused-vars */

  return (
    <div className={classes.root} style={{ backgroundImage: `url(${getAssetUrl(location.asset)})` }}>
      <div className={classes.overlayFilter} />
      <div className={classes.content}>
        <h3 className={classes.title}>{location && location.name}</h3>
        <h5 className={classes.description}>{location.description}</h5>
      </div>
      <div className={classes.emptySpace} />
      <div className={classes.elementsAsk}>
        <h2 className={classes.mb1}>Ask About</h2>

        <div className={classes.elementsList}>
          {game.state.phaseData.locationElements &&
            game.state.phaseData.locationElements.map((selEl, selInd) => (
              <ElementImage
                key={`selEl_${selInd}`}
                id={selEl.element ? selEl.element.id : null}
                type={selEl.element ? selEl.element.type : ""}
                variant="rounded"
                size={(window.innerWidth * 0.45) / 5}
                color={"white"}
                textBg={true}
                badge={{
                  id: selEl.playerId,
                  type: "players",
                  showTitle: true,
                  placement: "top"
                }}
              />
            ))}
        </div>
      </div>
    </div>
  );
});
