import React from "react";
import ReactDOM from "react-dom";

import createStore from "storeon";
import StoreContext from "storeon/react/context";
import authStore from "stores/auth";
import errorStore from "stores/error";
import gameStore from "stores/game";
import uiStore from "stores/ui";
import displayStore from "stores/display";
import App from "modules/App";

const store = createStore([
  errorStore,
  gameStore,
  uiStore,
  displayStore,
  authStore,
  process.env.NODE_ENV !== "production" && require("storeon/devtools")
  //process.env.NODE_ENV !== "production" && require("storeon/devtools/logger")
]);

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StoreContext.Provider value={store}>
    <App />
  </StoreContext.Provider>,
  rootElement
);
