import React from "react";
import withStyles from "react-jss";
import useStoreon from "storeon/react";

import PreIntro from "modules/Main/Intro/PreIntro";
import Intro from "modules/Main/Intro/Intro";
import PostIntro from "modules/Main/Intro/PostIntro";

import { commonStyles } from "services/theme";

const styles = {
  ...commonStyles
};

export default withStyles(styles)(({ classes }) => {
  /* eslint-disable no-unused-vars */
  const { game } = useStoreon("game");
  /* eslint-enable no-unused-vars */

  return (
    <React.Fragment>
      {game.state && game.state.phase === "PreIntro" && <PreIntro />}
      {game.state && game.state.phase === "Intro" && <Intro />}
      {game.state && game.state.phase === "PostIntro" && <PostIntro />}
    </React.Fragment>
  );
});
