export default store => {
  store.on("@init", () => ({
    display: {
      notificationsQueue: []
    }
  }));

  store.on("display/addNotificationQueue", ({ display }, newNotifications) => {
    const notificationsToAdd = [];
    newNotifications.array.forEach(notification => {
      if (!display.notificationsQueue.find(c => c.notification.text === notification.text)) {
        notificationsToAdd.push({ notification, type: newNotifications.type });
      }
    });
    return { display: { ...display, notificationsQueue: [...display.notificationsQueue, ...notificationsToAdd] } };
  });
};
