import React, { useState } from "react";
import withStyles from "react-jss";
import useStoreon from "storeon/react";
import * as serviceWorker from "serviceWorker";

import Main from "modules/Main/Main";

import "./Normalize.css";
import "./App.css";

const styles = {
  errorDiv: {
    position: "absolute",
    top: 20,
    right: 20,
    width: 200,
    padding: 20,
    background: "#fff",
    color: "#a00000"
  }
};

export default withStyles(styles)(({ classes }) => {
  const { dispatch, ui, error } = useStoreon("ui", "error"); // []  = run only once
  const [showUpdate, setShowUpdate] = useState(false);

  // enable service workers
  if (process.env.NODE_ENV === "production") {
    serviceWorker.register({
      onUpdate: installingWorker => {
        console.log(installingWorker);
        setShowUpdate(true);
      }
    });
  }

  return (
    <div className="App">
      {ui.loading === true && <div className="full-over">LOADING</div>}

      {showUpdate && <div id="snackbar">A new version of this app is available. Reloading...</div>}

      {<Main />}

      {error.visible && (
        <div className={classes.errorDiv}>
          {error.text} -<button onClick={() => dispatch("error/setError", { visible: false, text: "" })}>close</button>
        </div>
      )}
    </div>
  );
});
