import React, { useMemo } from "react";
import withStyles from "react-jss";
import useStoreon from "storeon/react";

import BlockText from "modules/Components/BlockText";
import { getAssetUrl } from "services/utilities";
import { commonStyles } from "services/theme";

const styles = {
  ...commonStyles
};

export default withStyles(styles)(({ classes }) => {
  const { game } = useStoreon("game");

  const getEventBg = useMemo(() => {
    const filename = game.state.phaseData && game.state.phaseData.event && getAssetUrl(game.state.phaseData.event.asset);
    return filename ? `url(${filename})` : "none";
  }, [game.state.phaseData]);

  const getBlockCharacterId = useMemo(() => {
    return game.state.phaseData.event && game.state.phaseData.event.choice
      ? game.state.phaseData.event.choice.blocks[game.state.phaseData.blockData.blockIndex].characterID
      : false;
  }, [game.state.phaseData]);

  return (
    <React.Fragment>
      {game.state.phaseData && game.state.phase === "EventChoiceResult" && (
        <BlockText
          bgImage={getEventBg}
          character={getBlockCharacterId}
          mainName={game.state.phaseData.event.name}
          blocks={game.state.phaseData.choice.blocks}
          blockIndex={game.state.phaseData.blockData.blockIndex}
        ></BlockText>
      )}
    </React.Fragment>
  );
});
