import React, { useMemo } from "react";
import withStyles from "react-jss";
import useStoreon from "storeon/react";

import BlockText from "modules/Components/BlockText";
import { getAssetUrl } from "services/utilities";
import { commonStyles } from "services/theme";

const styles = {
  ...commonStyles
};

export default withStyles(styles)(({ classes }) => {
  const { game } = useStoreon("game");

  const getLocationBg = useMemo(() => {
    const filename = game.state.phaseData && getAssetUrl(game.state.phaseData.location.asset);
    return filename ? `url(${filename})` : "none";
  }, [game.state.phaseData]);

  const getBlockCharacterId = useMemo(() => {
    return game.state.phaseData.location.blocks[game.state.phaseData.blockData.blockIndex].characterID || false;
  }, [game.state.phaseData]);

  const getAllShownElements = useMemo(() => {
    const currentAskedElements = game.state.phaseData.locationElements.map(askedElement => askedElement.element);
    const allAskedElements = game.state.history.reduce((allAsked, h) => {
      // Only return those that are active in this block
      if (h.element === game.state.phaseData.selectedLocation) {
        h.asked.forEach(ask => {
          if (game.state.phaseData.blockData.conditions.includes(ask.id)) {
            allAsked = [...allAsked, ask];
          }
        });
      }
      return allAsked;
    }, currentAskedElements);
    return allAskedElements;
  }, [game.state.history, game.state.phaseData.blockData.conditions, game.state.phaseData.locationElements, game.state.phaseData.selectedLocation]);

  return (
    <React.Fragment>
      {game.state.phaseData && game.state.phase === "LocationVisit" && (
        <BlockText
          bgImage={getLocationBg}
          character={getBlockCharacterId}
          mainName={game.state.phaseData.location.name}
          blocks={game.state.phaseData.location.blocks}
          blockIndex={game.state.phaseData.blockData.blockIndex}
          allShownElements={getAllShownElements}
        ></BlockText>
      )}
    </React.Fragment>
  );
});
