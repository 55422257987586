import Firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyDbwMPhMogF6Awq9Zar1d-VH7jMjDfmM0o",
  authDomain: "nameless-noir-create.firebaseapp.com",
  databaseURL: "https://nameless-noir-create.firebaseio.com",
  projectId: "nameless-noir-create",
  storageBucket: "nameless-noir-create.appspot.com",
  messagingSenderId: "633627518880"
};

const FirebaseApp = Firebase.initializeApp(config);
const FirebaseAuth = FirebaseApp.auth();
const FirebaseDb = FirebaseApp.firestore();

const dbRoomCodes = FirebaseDb.collection("roomCodes");

export { Firebase, FirebaseApp, FirebaseAuth, FirebaseDb, dbRoomCodes };
