import React, { useState, useEffect, useCallback } from "react";
import withStyles from "react-jss";
import useStoreon from "storeon/react";
import { Howl, Howler } from "howler";

import { joinRoom } from "services/gameService";
import { useInputValue } from "services/hooks";
import { AuthSignin, AuthCheck, getRoomFromCode } from "services/dbService";
import { commonStyles } from "services/theme";

import Lobby from "modules/Main/Lobby/Lobby";
import Game from "modules/Main/Game";
import API from "services/api";

const styles = {
  ...commonStyles,
  root: {
    position: "relative",
    height: "100vh",
    overflow: "hidden"
  }
};

export default withStyles(styles)(({ classes }) => {
  /* eslint-disable no-unused-vars */
  const [init, setInit] = useState(false);
  const [music, setMusic] = useState([]);
  const [currentPhase, setCurrentPhase] = useState(null);
  const [currentPlayer, setCurrentPlayer] = useState(false);
  const [isLobby, setIsLobby] = useState(true);
  const [hash, setHash] = useState(null);
  const { ...gameCode } = useInputValue("");
  const { dispatch, game } = useStoreon("game");
  const [timer, setTimer] = useState(null);
  /* eslint-enable no-unused-vars */

  const checkAuthStatus = useCallback(async () => {
    dispatch("ui/setLoading", true);
    let user = await AuthCheck();
    if (!user) {
      user = await AuthSignin();
    }
    dispatch("auth/setUser", user);
    dispatch("ui/setLoading", false);
    dispatch("auth/setLogged", user ? true : false);
  }, [dispatch]);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  /* Handle Music on Phase changes */
  useEffect(() => {
    const isLobby =
      (game.state && game.story && !game.state.started) ||
      (game.state &&
        game.state.started &&
        game.state.activePlayers &&
        game.state.selectedPlayers &&
        game.state.activePlayers.length !== game.state.selectedPlayers.length);
    setIsLobby(isLobby);
    if (game.state && (game.state.phase !== undefined || isLobby)) {
      let newPhase = game.state.phase;
      if (isLobby) {
        newPhase = "Lobby";
      }
      if (currentPhase !== newPhase) {
        setCurrentPhase(newPhase);

        /* Check and play music */
        const options = {
          src: null,
          fadeIn: false,
          autoplay: true,
          loop: false,
          volume: 1
        };

        let asset = null;
        switch (newPhase) {
          case "Lobby":
            options.src = game.story.music && game.story.music.file;
            options.fadeIn = true;
            options.loop = true;
            break;
          case "PreIntro":
            options.src = game.story.introAudio && game.story.introAudio.file;
            options.fadeIn = true;
            options.loop = true;
            break;
          case "Intro":
            if (!currentPhase) {
              options.src = game.story.introAudio && game.story.introAudio.file;
              options.fadeIn = true;
              options.loop = true;
            }
            break;
          case "PostIntro":
            if (!currentPhase) {
              options.src = game.story.introAudio && game.story.introAudio.file;
              options.fadeIn = true;
              options.loop = true;
            }
            break;
          case "EventIntro":
            asset = game.state.phaseData && game.state.phaseData.event && game.state.phaseData.event.music;
            options.src = asset && asset.file;
            options.fadeIn = true;
            options.loop = true;
            break;
          case "EventChoices":
            if (!currentPhase) {
              asset = game.state.phaseData && game.state.phaseData.event && game.state.phaseData.event.music;
              options.src = asset && asset.file;
              options.fadeIn = true;
              options.loop = true;
            }
            break;
          case "EventChoiceResult":
            if (!currentPhase) {
              asset = game.state.phaseData && game.state.phaseData.event && game.state.phaseData.event.music;
              options.src = asset && asset.file;
              options.fadeIn = true;
              options.loop = true;
            }
            break;
          case "EventRecap":
            if (!currentPhase) {
              asset = game.state.phaseData && game.state.phaseData.event && game.state.phaseData.event.music;
              options.src = asset && asset.file;
              options.fadeIn = true;
              options.loop = true;
            }
            break;
          case "LocationSelect":
            options.src = game.story.music && game.story.music.file;
            options.fadeIn = true;
            options.loop = true;
            break;
          case "LocationVisit":
            asset = game.state.phaseData && game.state.phaseData.location && game.state.phaseData.location.music;
            options.src = asset && asset.file;
            options.fadeIn = true;
            options.loop = true;
            break;
          case "LocationRecap":
            if (!currentPhase) {
              asset = game.state.phaseData && game.state.phaseData.event && game.state.phaseData.event.music;
              options.src = asset && asset.file;
              options.fadeIn = true;
              options.loop = true;
            }
            break;
          default:
            options.src = null;
        }

        if (options.src) {
          const audio = new Howl({
            src: options.src,
            autoplay: false,
            loop: options.loop,
            volume: options.volume,
            onplayerror: () => {
              audio.once("unlock", () => {
                if (options.fadeIn) {
                  audio.fade(0, 1, 8000);
                } else {
                  audio.play();
                }
              });
            }
          });

          if (options.autoplay) {
            audio.play();
            if (options.fadeIn) {
              audio.fade(0, 1, 8000);
            }
          }
          setMusic([...music, audio]);
        }
      }
    }
  }, [game.state, game.story, currentPhase, music]);

  useEffect(() => {
    console.info(music);
    if (music.length > 1) {
      music[0].fade(music[0].volume(), 0, 4000);
      setTimeout(() => {
        music[0].unload();
        setMusic([...music.slice(1)]);
      }, 4500);
    }
  }, [music]);
  /* Handle Music on Phase changes */

  useEffect(() => {
    const pl = game.state && game.state.currentPlayer && game.story && game.story.players && game.story.players.find(p => p.id === game.state.currentPlayer);
    if (pl && pl.name) {
      setCurrentPlayer(pl);
    }
  }, [game.state, game.story]);

  const clearMusic = useCallback(() => {
    if (music.length > 0) {
      music.forEach(m => {
        m.unload();
      });
    }
    setMusic([]);
  }, [music]);

  useEffect(() => {
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  /* Handle Options change*/
  useEffect(() => {
    if (game.state && game.state.options) {
      if (!game.state.options.music) {
        Howler.volume(0);
      } else {
        Howler.volume(1);
      }
    }
  }, [game.state]);
  /* Handle Options change*/

  const initGame = useCallback(
    async code => {
      getRoomFromCode(code)
        .then(gameID => {
          console.log("Trying to join ", gameID);
          joinRoom(gameID).then(newState => {
            console.log("Joined", newState);
            dispatch("game/setState", newState);
            setInit(true);
            // Add auto-updates for new states
            setTimer(
              setInterval(async () => {
                const updatedState = await API().post("/state", { uid: null });
                updatedState.currentState.checkins = updatedState.checkins;
                updatedState.currentState.now = updatedState.now;
                dispatch("game/setState", updatedState.currentState);
              }, 2000)
            );
          });
        })
        .catch(error => {
          console.error(error);
          dispatch("ui/setLoading", false);
          dispatch("error/setError", { visible: true, text: error.message });
          clearMusic();
        });
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [dispatch]
    /* eslint-enable react-hooks/exhaustive-deps */
  );

  useEffect(() => {
    if (!init) {
      if (window.location && window.location.hash) {
        var h = window.location.hash.substring(1); //Puts hash in variable, and removes the # character
        setHash(h);
        initGame(h);
      }
    }
  }, [initGame, init]);

  return (
    <React.Fragment>
      {!init && (
        <React.Fragment>
          <h3>Enter code to connect to Game</h3>
          {!hash && <input {...gameCode} id="gameCode" name="gameCode" autoComplete="gameCode" autoFocus required className={classes.inputInline} />}

          <button onClick={() => initGame(hash ? hash : gameCode.value)} className={`${classes.btn} ${classes.margined1}`}>
            Enter Game
          </button>
        </React.Fragment>
      )}

      {init && isLobby && <Lobby />}

      {init && !isLobby && <Game currentPlayer={currentPlayer} code={hash ? hash : gameCode.value} />}
    </React.Fragment>
  );
});
