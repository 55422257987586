import { extend } from "services/utilities";

export default store => {
  store.on("@init", () => ({
    game: {
      connected: [],
      meta: null,
      state: null,
      story: null,
      storyMeta: null
    }
  }));

  store.on("game/setState", ({ game }, newGame) => {
    /*const ret = {};
    if (newGame.story && ((game && !game.story) || (game.story && newGame.story && game.story.name !== newGame.story.name))) {
      ret.story = newGame.story;
      ret.meta = newGame.meta;
      ret.storyMeta = newGame.storyMeta;
    }
    if (newGame.connected) {
      ret.connected = newGame.connected;
    }
    ret.state = newGame.state;
    if (newGame.checkins) {
      ret.checkins = newGame.checkins;
    }
    if (newGame.now) {
      ret.now = newGame.now;
    }

    return { game: { ...game, ...ret } };
    */
    extend(game, newGame);
    return { game };
  });
};
