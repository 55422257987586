import React, { useEffect, useState, useCallback } from "react";
import withStyles from "react-jss";
import useStoreon from "storeon/react";

import IntroAll from "modules/Main/Intro/IntroAll";
import EventIntro from "modules/Main/Event/EventIntro";
import EventChoices from "modules/Main/Event/EventChoices";
import EventChoiceResult from "modules/Main/Event/EventChoiceResult";
import EventRecap from "modules/Main/Event/EventRecap";
import LocationSelect from "modules/Main/LocationSelect/LocationSelect";
import LocationVisit from "modules/Main/LocationVisit/LocationVisit";
import LocationRecap from "modules/Main/LocationVisit/LocationRecap";

import ElementImage from "modules/Components/ElementImage";

import { formatMentionText, _h, isActivePlayer } from "services/utilities";
import { commonStyles } from "services/theme";

const styles = {
  ...commonStyles,
  info: {
    display: "inline-block",
    position: "fixed",
    top: 0,
    left: 20,
    zIndex: 2,
    textAlign: "left"
  },
  playerIcons: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center"
  },
  cluesPopup: {
    position: "fixed",
    top: "20%",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 99,
    fontSize: "1em",
    padding: "1em",
    background: "rgba(0, 0, 0, 0.95)",
    borderRadius: 20
  },
  cluesAnimateIn: {
    opacity: 1,
    animationName: "$fadeZoomIn",
    animationDuration: "1s"
  },
  cluesAnimateOut: {
    opacity: 0,
    animationName: "$fadeZoomOut",
    animationDuration: "1s"
  },
  "@keyframes fadeZoomIn": {
    from: { opacity: 0 },
    to: { opacity: 1 }
  },
  "@keyframes fadeZoomOut": {
    from: { opacity: 1 },
    to: { opacity: 0 }
  }
};

export default withStyles(styles)(({ classes, currentPlayer, code }) => {
  /* eslint-disable no-unused-vars */
  const { dispatch, game, display } = useStoreon("game", "display");
  const [visibleClue, setVisibleClue] = useState(null);
  const [notificationAnimating, setNotificationAnimating] = useState(false);
  const [cluePopupClasses, setCluePopupClasses] = useState("");
  /* eslint-enable no-unused-vars */

  useEffect(() => {
    const valid = game.state && game.state.phaseData && game.state.phaseData.blockData;
    if (valid && game.state.phaseData.blockData.clues) {
      dispatch("display/addNotificationQueue", { array: game.state.phaseData.blockData.clues, type: "clues" });
    }
    if (valid && game.state.phaseData.blockData.objectives) {
      const newObjectives = game.state.phaseData.blockData.objectives.map(obj => {
        const objective = game.story.objectives.find(o => o.id === obj.id);
        return {
          id: obj.id,
          belongsTo: objective.belongsTo,
          text: objective.description,
          parent: objective.parent
        };
      });
      dispatch("display/addNotificationQueue", { array: newObjectives, type: "objectives" });
    }
  }, [game.state, dispatch, game.story]);

  useEffect(() => {
    if (!notificationAnimating && display.notificationsQueue.filter(c => !c.shown).length > 0) {
      const showClue = display.notificationsQueue.find(c => !c.shown);
      showClue.shown = true;
      setVisibleClue(showClue);
      setCluePopupClasses(classes.cluesAnimateIn);
      setNotificationAnimating(true);
      setTimeout(() => {
        setCluePopupClasses(classes.cluesAnimateOut);
        setTimeout(() => {
          setVisibleClue(null);
          setNotificationAnimating(false);
          dispatch("display/addNotificationQueue", { array: [], type: "" });
        }, 3000);
      }, 4000);
    }
  }, [display.notificationsQueue, dispatch, classes.cluesAnimateIn, classes.cluesAnimateOut, notificationAnimating]);

  const checkPlayer = useCallback(
    p => {
      let color = "red";
      const active = isActivePlayer(p.uid, game.checkins, game.now);
      if (active) {
        color = currentPlayer.id === p.playerId ? "white" : "players";
      }
      return color;
    },
    [game.checkins, game.now, currentPlayer.id]
  );

  const getPopupHeaderText = type => {
    let header = "";
    switch (type) {
      case "clues":
        header = "New clue found!";
        break;
      case "objectives":
        header = "New objective";
        break;
      default:
        header = "";
    }
    return header;
  };

  return (
    <React.Fragment>
      {game.story && game.state && (
        <div className={classes.info}>
          <div className={classes.playerIcons}>
            {game.state.activePlayers &&
              game.state.activePlayers.map(p => (
                <div key={`avatar_${p.playerId}`}>
                  <ElementImage id={p.playerId} type="players" variant="rounded" size={Math.floor(window.innerWidth / 22)} color={checkPlayer(p)} />
                </div>
              ))}
            {code && game.state.options.showCode && (
              <div className={classes.fontSmaller2}>
                <p>
                  {game.story.name} - Round {game.state.round} <br /> Code: {code}
                </p>
                {game.state.options && game.state.options.music && <p>Music On</p>}
              </div>
            )}
          </div>
        </div>
      )}
      {game.state && (game.state.phase === "Intro" || game.state.phase === "PreIntro" || game.state.phase === "PostIntro") && <IntroAll />}
      {game.state && game.state.phase === "EventIntro" && <EventIntro currentPlayer={currentPlayer} />}
      {game.state && game.state.phase === "EventChoices" && <EventChoices currentPlayer={currentPlayer} />}
      {game.state && game.state.phase === "EventChoiceResult" && <EventChoiceResult currentPlayer={currentPlayer} />}
      {game.state && game.state.phase === "EventRecap" && <EventRecap currentPlayer={currentPlayer} />}
      {game.state && game.state.phase === "LocationSelect" && <LocationSelect currentPlayer={currentPlayer} />}
      {game.state && game.state.phase === "LocationVisit" && <LocationVisit currentPlayer={currentPlayer} />}
      {game.state && game.state.phase === "LocationRecap" && <LocationRecap currentPlayer={currentPlayer} />}

      {visibleClue && (
        <div className={`${classes.cluesPopup} ${cluePopupClasses}`}>
          <div>{getPopupHeaderText(visibleClue.type)}!</div>
          {visibleClue.type === "clues" && <div dangerouslySetInnerHTML={_h(formatMentionText(visibleClue.notification.text))} />}
          {visibleClue.type === "objectives" && <div>{visibleClue.notification.text}</div>}
        </div>
      )}
    </React.Fragment>
  );
});
