import React from "react";
import withStyles from "react-jss";
import useStoreon from "storeon/react";

import { commonStyles } from "services/theme";

const styles = {
  ...commonStyles
};

export default withStyles(styles)(({ classes, currentPlayer }) => {
  /* eslint-disable no-unused-vars */
  const { game } = useStoreon("game");
  /* eslint-enable no-unused-vars */

  return (
    <div className={`${classes.container} ${classes.fullHeight} ${classes.flexMiddle}`}>
      <h4> Location Recap </h4>
      <h4>-not yet implemented-</h4>
      {game.state.phaseData && (
        <div>
          <h2>Continue</h2>
        </div>
      )}
    </div>
  );
});
