import { useState, useEffect } from "react";
import { Howl } from "howler";

const useInputValue = initialValue => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    onChange: e => setValue(e.target.value)
  };
};

const useAudio = (game, options) => {
  const [music, setMusic] = useState(null);

  useEffect(() => {
    if (game.story && options.src && options.src.file && !music) {
      const opt = {
        src: options.src.file || null,
        fadeIn: options.fadeIn || false,
        autoplay: options.autoplay || true,
        loop: options.loop || false,
        volume: options.volume || 1
      };
      if (opt.src) {
        const audio = new Howl({
          src: opt.src,
          autoplay: opt.autoplay,
          loop: opt.loop,
          volume: opt.volume,
          onplayerror: () => {
            audio.once("unlock", () => {
              if (options.fadeIn) {
                audio.fade(0, 1, 8000);
              } else {
                audio.play();
              }
            });
          }
        });
        setMusic(audio);
      }
    }
  }, [game.story, music, options]);

  useEffect(() => {
    if (music) {
      if (options.fadeIn) {
        music.fade(0, 1, 8000);
      } else {
        music.play();
      }
    }

    return () => {
      if (music) {
        music.fade(1, 0, 4000);
        setTimeout(() => {
          music.unload();
        }, 4500);
      }
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [music]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return { music };
};

export { useInputValue, useAudio };
