import React, { useState, useRef, useEffect } from "react";
import withStyles from "react-jss";
import useStoreon from "storeon/react";
import PrismaZoom from "modules/Components/PrismaZoom";

import { useAudio } from "services/hooks";
import { getCommonDiscoveredElementsByType } from "services/gameService";
import { getAssetUrl, getImageSize } from "services/utilities";
import { bgColors, commonStyles } from "services/theme";

import LocationDetails from "modules/Main/LocationSelect/LocationDetails";
import ElementImage from "modules/Components/ElementImage";

const styles = {
  ...bgColors,
  ...commonStyles,
  zoom: {
    position: "relative",
    minWidth: "100vw"
  },
  storyMap: {
    position: "relative",
    zIndex: 1,
    minWidth: "100vw"
  },
  mapImage: {
    //width: '100%',
  },
  location: {
    position: "absolute",
    top: 20,
    left: 20,
    textAlign: "center",
    fontSize: "1em",
    display: "inline-block",
    margin: 15,
    lineHeight: 1,
    zIndex: 2
  },
  mapSidePopover: {
    display: "none",
    position: "fixed",
    height: "100vh",
    width: "45vw",
    zIndex: 20,
    background: "#333",
    color: "#eee",
    top: 0,
    right: "-45vw",
    boxShadow: "-2.2vw 0px 25px 0px rgba(0,0,0,0.85)"
  },
  sideAnimateIn: {
    display: "block",
    right: 0,
    animationName: "$slideInRight",
    animationDuration: "2.5s"
  },
  sideAnimateOut: {
    display: "block",
    right: "-45vw",
    animationName: "$slideOutRight",
    animationDuration: ".5s"
  },
  "@keyframes slideInRight": {
    from: { right: "-45vw" },
    to: { right: 0 }
  },
  "@keyframes slideOutRight": {
    from: { right: 0 },
    to: { right: "-45vw" }
  }
};

export default withStyles(styles)(({ classes, currentPlayer }) => {
  /* eslint-disable no-unused-vars */
  const prismaRef = useRef(null);
  const [sideClasses, setSideClasses] = useState("");
  const [prevPos, setPrevPos] = useState({ x: 0, y: 0, id: null });
  const [openLocation, setOpenLocation] = useState(null);
  const [locationsArray, setLocationsArray] = useState([]);
  const [isAnimating, setIsAnimating] = useState(false);
  const [mapSize, setMapSize] = useState(null);
  const { game } = useStoreon("game");
  const { music } = useAudio(game, { src: game.story.music, fadeIn: true, loop: true });
  /* eslint-enable no-unused-vars */

  const resetMapView = () => {
    const prisma = prismaRef.current;
    if (mapSize) {
      prisma.zoomToZone(mapSize.posW, mapSize.posH, mapSize.width, mapSize.height);
    } else {
      getImageSize(getAssetUrl(game.story.map), sizes => {
        const w = sizes.width;
        const h = sizes.height;
        const posH = (h - window.innerHeight) / (w / h);
        prisma.zoomToZone(10, posH, w, h);
        setMapSize({ ...sizes, posW: 10, posH });
      });
    }
  };

  useEffect(() => {
    if (isAnimating || locationsArray.length === 0) {
    } else {
      setIsAnimating(true);
      const location = locationsArray[0];
      if (location) {
        const prisma = prismaRef.current;
        const size = game.story.iconSizes;
        prisma.zoomToZone(location.coordinates.x + size, location.coordinates.y, size * 3, size * 3);
        setPrevPos({ x: location.coordinates.x, y: location.coordinates.y, id: location.id });
        setOpenLocation(location);
        setSideClasses(classes.sideAnimateIn);
      }
      setLocationsArray([...locationsArray.slice(1)]);
      setTimeout(() => {
        setIsAnimating(false);
      }, 2000);
    }
  }, [locationsArray, isAnimating, classes.sideAnimateIn, game.story.iconSizes]);

  useEffect(() => {
    if (prismaRef.current && game.state.phaseData && game.story) {
      // const prisma = prismaRef.current;
      if (!game.state.phaseData.selectedLocation || (prevPos.id && prevPos.id !== game.state.phaseData.selectedLocation)) {
        setSideClasses(classes.sideAnimateOut);
      }

      if (game.state.phaseData.selectedLocation) {
        const location = game.story.locations.find(l => l.id === game.state.phaseData.selectedLocation);
        if (location) {
          const size = game.story.iconSizes;
          // prisma.refs.layout
          const distance = Math.hypot(location.coordinates.x - prevPos.x, location.coordinates.y - prevPos.y);

          if (distance < size * 7) {
            setLocationsArray([...locationsArray, location]);
          } else {
            // prisma.reset();
            resetMapView();
            setLocationsArray([...locationsArray, null, location]);
          }
        } else {
          setPrevPos({ x: 0, y: 0, id: null });
          // prisma.reset();
          resetMapView();
          //setLocationsArray([...locationsArray, null]);
        }
      } else {
        setPrevPos({ x: 0, y: 0, id: null });
        // prisma.reset();
        resetMapView();
        //setLocationsArray([...locationsArray, null]);
        //prisma.zoomIn(0.3);
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [game.story, game.state.phaseData.selectedLocation]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /*const panMap = () => {
    const prisma = prismaRef.current;
    const panTime = 5;

    const currentZoom = 1.3;
    const maxWidth = window.innerWidth / currentZoom;
    const maxHeight = window.innerHeight / currentZoom;
    const newX = randFromTo(0, maxWidth - window.innerWidth / 2);
    const newY = randFromTo(0, maxHeight - window.innerHeight / 2);
    prisma.zoomToZone(newX, newY, maxWidth, maxHeight, panTime);
    setTimeout(() => {
      if (!game.state.phaseData.selectedLocation) {
        panMap();
      }
    }, panTime * 900);
  };*/

  return (
    <React.Fragment>
      {game.story && (
        <React.Fragment>
          <PrismaZoom ref={prismaRef} className={classes.zoom} leftBoundary={0} topBoundary={0} animDuration={2} maxZoom={3} minZoom={0.1}>
            {game.story.map && (
              <div className={classes.storyMap}>
                <img className={classes.mapImage} src={getAssetUrl(game.story.map)} alt="" />
              </div>
            )}
            {game &&
              getCommonDiscoveredElementsByType(game, "locations").map(location => (
                <div
                  key={location.id}
                  className={classes.location}
                  style={{
                    left: location.coordinates.x,
                    top: location.coordinates.y
                  }}
                >
                  <ElementImage id={location.id} type="locations" variant="rounded" size={game.story.iconSizes} />
                </div>
              ))}
          </PrismaZoom>
          {openLocation && (
            <div className={`${classes.mapSidePopover} ${sideClasses}`}>
              <LocationDetails location={game.story.locations.find(l => l.id === openLocation.id)} />
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
});
