import React from "react";
import withStyles from "react-jss";
import useStoreon from "storeon/react";

import { commonStyles } from "services/theme";

const styles = {
  ...commonStyles
};

export default withStyles(styles)(({ classes }) => {
  /* eslint-disable no-unused-vars */
  const { game } = useStoreon("game");
  /* eslint-enable no-unused-vars */

  return (
    <div className={`${classes.container} ${classes.fullHeight} ${classes.flexMiddle}`}>
      <h1>Story Intro</h1>
      <div>{game.state && game.state.phase === "Intro" && game.story.intro[game.state.phaseData.introStep].text}</div>
    </div>
  );
});
