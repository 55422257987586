import React from "react";
import withStyles from "react-jss";
import useStoreon from "storeon/react";

import ElementImage from "modules/Components/ElementImage";

import { commonStyles } from "services/theme";

const styles = {
  ...commonStyles,
  playersWrapper: {
    display: "flex",
    flexWrap: "wrap"
  },
  playerDiv: {
    margin: " 0.5em 1em",
    padding: "0.5em",
    flexGrow: 1
  }
};

export default withStyles(styles)(({ classes }) => {
  /* eslint-disable no-unused-vars */
  const { game } = useStoreon("game");
  /* eslint-enable no-unused-vars */

  const getPlayerUserSelected = playerId => {
    if (game.state && game.state.activePlayers) {
      const userSelected = game.state.activePlayers.find(a => a.playerId === playerId);
      if (userSelected) {
        const found_user = game.connected.find(u => u.uid === userSelected.uid);
        return found_user && found_user.email;
      } else {
        return "  ";
      }
    }
  };

  return (
    <div className={`${classes.container} ${classes.mt1}`}>
      <div className={classes.fontSmaller1}>Connected Players: {game.connected && game.connected.length}</div>
      <h3>{game.storyMeta && game.storyMeta.name}</h3>
      <h4>{game.storyMeta && game.storyMeta.description}</h4>
      {!game.state.activePlayers || (game.connected.length !== game.state.activePlayers.length && <h2>Not all connected users have selected a player</h2>)}
      <div className={classes.playersWrapper}>
        {game.story &&
          game.story.players.map(player => (
            <div key={player.id} className={classes.playerDiv}>
              <ElementImage id={player.id} type={"players"} size={150} imageOnly={true} />
              <h4>{player.name}</h4>
              <h5>{getPlayerUserSelected(player.id)}</h5>
            </div>
          ))}
      </div>
    </div>
  );
});
