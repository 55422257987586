import React from "react";
import withStyles from "react-jss";
import useStoreon from "storeon/react";

import { commonStyles } from "services/theme";

const styles = {
  ...commonStyles
};

export default withStyles(styles)(({ classes, currentPlayer }) => {
  /* eslint-disable no-unused-vars */
  const { game } = useStoreon("game");
  /* eslint-enable no-unused-vars */

  return (
    <div className={`${classes.container} ${classes.fullHeight} ${classes.flexMiddle}`}>
      <h4> EVENT RECAP </h4>
      <h4>-not yet implemented-</h4>
      {game.state.phaseData && game.state.phase === "EventRecap" && (
        <React.Fragment>
          {game.state.phaseData.choice.isEnd && !game.state.phaseData.choice.canUndo && <h1>GAME OVER</h1>}
          {game.state.phaseData.choice.isEnd && game.state.phaseData.choice.canUndo && (
            <React.Fragment>
              <h1>GAME OVER</h1>
              <h3>Make another choice?</h3>
            </React.Fragment>
          )}
          {game.state.phaseData.choice.isEnd === false && <h1>Continue</h1>}
        </React.Fragment>
      )}
    </div>
  );
});
