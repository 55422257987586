import API from "services/api";

// id is gameID for create, roomID in server for join
const joinRoom = async id => {
  console.log(`Joining as TV for room: `, id);

  const tokenData = await API().post("/login", { uid: null, isTv: true, roomid: id });
  await API().setToken(tokenData.token);
  const gameData = await API().post("/join", { id, user: null, isTv: true });
  return gameData;
};

const getCommonDiscoveredElementsByType = (game, type) => {
  return game.story[type].filter(element => game.state.discovered.common.find(e => e.id === element.id)) || [];
};

export { joinRoom, getCommonDiscoveredElementsByType };
