import { FirebaseAuth, dbRoomCodes } from "services/firebase";

const AuthCheck = () => {
  return new Promise((resolve, reject) => {
    FirebaseAuth.onAuthStateChanged(user => {
      if (user) {
        resolve(user);
      } else {
        resolve(false);
      }
    });
  });
};

const AuthSignin = () => {
  return new Promise((resolve, reject) => {
    FirebaseAuth.signInWithEmailAndPassword("app-main@namelessnoir.com", "nameless123!@#")
      .then(usr => {
        resolve(usr);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const getRoomFromCode = code => {
  return new Promise((resolve, reject) => {
    dbRoomCodes
      .where("code", "==", code)
      .get()
      .then(res => {
        if (!res.empty) {
          const data = res.docs[0].data();
          resolve(data.gameID);
        } else {
          reject({ message: "Wrong code" });
        }
      })
      .catch(error => {
        reject(error);
        console.error(error);
      });
  });
};

export { getRoomFromCode, AuthSignin, AuthCheck };
